import { formatDate } from 'utils/commonUtils';

export const getSharedFolderVersionsListData = (sharedFolderData) => {
  const items = sharedFolderData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item, index) => ({
    ...item,
    id: index,
    apiId: item.id,
    name: item.name,
    description: item.description,
    readUser: item.readUsers?.length,
    writeUser: item.writeUsers?.length,
    readGroup: item.readGroups?.length,
    writeGroup: item.writeGroups?.length,
    readUserList: item.readUsers,
    writeUserList: item.writeUsers,
    readGroupList: item.readGroups,
    writeGroupList: item.writeGroups,
    version: item.version,
    createdBy: item.createdBy,
    createdAt: formatDate(item.createdAt),
    modifiedBy: item.modifiedBy,
    modifiedAt: formatDate(item.modifiedAt),
    archived: item.archived,
  }));
};
