import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { GridForm, BoxWrapper } from 'components/Ui/styled';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import Typography from '@mui/material/Typography';
import { FormInputTextIcon } from 'components/Common/Forms/FormTextFieldIcon';
import { FormInputAutoCompleteSearch } from 'components/Common/Forms/FormAutoCompleteSearch';
import { getSearchedStudents } from 'layouts/students/service/studentsApi';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import {
  PaperPropsCheck,
  attendanceAuditSuccess,
  emptyValueError,
  duplicatesError,
  booleanOptions,
  emptyStudentsError,
  characterLimit,
} from 'constants';
import {
  postAttendanceAudit,
  putAttendanceAudit,
} from 'layouts/auditManagement/services/attendanceAuditsApi';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  showBackdropLoader,
  onSave,
  fieldValidation,
  DropdownInputList,
  getOptionLabel,
  refinePayload,
} from 'utils/commonUtils';
import {
  setName,
  setSemesters,
  setIncludePreviousSemesters,
  setIncludeDropped,
  setStudentIds,
  resetAttendanceAuditForm,
  setNewStudentIds,
} from '../../store/attendanceAuditSlice/attendanceAuditSlice';

const attendanceAuditSchema = yup.object().shape({
  name: fieldValidation(characterLimit),
});

const AttendanceAuditDialog = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const {
    open,
    setOpen,
    isFormLoading,
    fetchAttendanceAudits,
    attendanceAuditId,
    setAttendanceAuditId,
    semesterDropDownList,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [studentOptions, setStudentOptions] = useState([]);
  const [studentSelect, setStudentSelect] = useState(null);
  const [studentSearchQuery, setStudentSearchQuery] = useState('');
  const [isStudentSearchloading, setIsStudentSearchloading] = useState(false);

  const attendanceAuditForm = useSelector(
    (state) => state.attendanceAuditForm.item
  );

  const defaultValue = {
    id: attendanceAuditForm?.id,
    name: attendanceAuditForm?.name,
    semesters: attendanceAuditForm?.reportParams?.semesters,
    studentIds: attendanceAuditForm?.reportParams?.studentIds,
    includePreviousSemesters: attendanceAuditForm?.includePreviousSemesters,
    includeDropped: attendanceAuditForm?.includeDropped,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(attendanceAuditSchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    fetchSearchedStudents();
  }, [studentSearchQuery]);

  const semesterDropDownLists = semesterDropDownList?.map((item, index) => ({
    id: item,
    value: item,
  }));

  const fetchSearchedStudents = async () => {
    setIsStudentSearchloading(true);
    const searchedStudents = await getSearchedStudents(
      studentSearchQuery,
      attendanceAuditForm?.includePreviousSemesters,
      attendanceAuditForm?.includeDropped,
      20
    );
    setStudentOptions(searchedStudents?.items);
    setIsStudentSearchloading(false);
  };

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.id,
    defaultValue.name,
    defaultValue.semesters,
    defaultValue.includePreviousSemesters,
    defaultValue.includeDropped,
    defaultValue.studentIds,
  ]);

  const handleStudentId = (e) => {
    if (e.key !== 'Enter') {
      return;
    }
    if (!studentSelect) {
      setOpenNotification(true);
      setNotificationMessage(emptyValueError);
      return;
    }
    if (
      attendanceAuditForm?.reportParams?.studentIds.includes(
        studentSelect?.studentId
      )
    ) {
      setOpenNotification(true);
      setNotificationMessage(duplicatesError);
      return;
    }
    dispatch(setNewStudentIds(studentSelect?.studentId));
  };

  const handleSemesterChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(
      setSemesters(typeof value === 'string' ? value.split(',') : value)
    );
  };

  const handleClose = () => {
    reset(defaultValue);
    setAttendanceAuditId(null);
    setStudentSelect(null);
    setStudentOptions([]);
    dispatch(resetAttendanceAuditForm());
    setStudentSearchQuery('');
    setOpen(false);
  };

  const onSubmit = async () => {
    const payload = {
      name: attendanceAuditForm?.name,
      semesters: attendanceAuditForm?.reportParams?.semesters,
      studentIds: attendanceAuditForm?.reportParams?.studentIds,
    };
    if (defaultValue?.studentIds?.length === 0) {
      setOpenNotification(true);
      setNotificationMessage(emptyStudentsError);
      return;
    }
    onSave(
      attendanceAuditSuccess,
      attendanceAuditForm.isNewAttendanceAudit,
      postAttendanceAudit,
      putAttendanceAudit,
      refinePayload(payload),
      attendanceAuditId,
      fetchAttendanceAudits,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {showBackdropLoader(isFormLoading)}
      <Dialog open={open} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Attendance audit', handleClose)}
        <BoxWrapper>
          <GridForm container spacing={2}>
            <Grid item xs={12}>
              <FormInputText
                name="name"
                type="text"
                control={control}
                label="Name"
                helperText={errors?.name?.message}
                errors={!!errors.name}
                register={register('name', {
                  onChange: (e) => dispatch(setName(e.target.value)),
                })}
              />
              <FormInputDropdown
                name="semesters"
                type="text"
                control={control}
                multiple={true}
                checked={defaultValue.semesters}
                renderValue={(selected) => (
                  <DropdownInputList selectedValues={selected} />
                )}
                options={semesterDropDownLists}
                label="Semesters"
                register={register('semesters', {
                  onChange: (e) => handleSemesterChange(e),
                })}
              />
              <FormInputDropdown
                name="includePreviousSemesters"
                type="text"
                control={control}
                options={booleanOptions}
                label="Include previous semesters for students search"
                register={register('includePreviousSemesters', {
                  onChange: (e) =>
                    dispatch(setIncludePreviousSemesters(e.target.value)),
                })}
              />
              <FormInputDropdown
                name="includeDropped"
                type="text"
                control={control}
                options={booleanOptions}
                label="Include dropped students for students search"
                register={register('includeDropped', {
                  onChange: (e) => dispatch(setIncludeDropped(e.target.value)),
                })}
              />
              <FormInputAutoCompleteSearch
                label="Student Ids"
                placeholder="Search students, select student and press enter"
                onKeyDown={handleStudentId}
                options={studentOptions}
                setSelectedInput={setStudentSelect}
                setInputSearchQuery={setStudentSearchQuery}
                isSearchloading={isStudentSearchloading}
                getOptionLabel={getOptionLabel}
              />
              <Typography variant="h6">Students</Typography>
              <FormInputTextIcon
                name="studentIds"
                type="text"
                control={control}
                label=""
                register={register('studentIds', {
                  onChange: (e) => dispatch(setStudentIds(e.target.value)),
                })}
              />
            </Grid>
            {renderSaveCancelButtons(
              handleClose,
              handleSubmit(onSubmit),
              loadingButton
            )}
          </GridForm>
        </BoxWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default AttendanceAuditDialog;
