import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Dialog from '@mui/material/Dialog';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import {
  GridForm,
  SmallIconButton,
  UploadBox,
  UploadText,
  RoundedCloudUpload,
  ButtonSpinnerProgress,
} from 'components/Ui/styled';
import {
  renderSaveCancelButtons,
  getDialogHeading,
  fieldValidation,
  refinePayload,
} from 'utils/commonUtils';
import { PaperPropsCheck, characterLimit } from 'constants';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';

const schema = yup.object().shape({
  title: fieldValidation(characterLimit),
  description: fieldValidation(4000),
});

const AssignmentDialog = (props) => {
  const { openAssignmentDialog, setOpenAssignmentDialog } = props;
  const dispatch = useDispatch();
  const [key, setKey] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploadUrl, setFileUploadUrl] = useState(null);
  const [isUploadAccess, setIsUploadAccess] = useState(false);
  const [uploadAccessError, setUploadAccessError] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValue = {
    title: '',
    description: '',
    dueDate: null,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const fileUpload = async () => {
    setLoadingButton(true);
    try {
      setLoadingButton(false);
    } catch (error) {
      setIsUploadAccess(true);
      setLoadingButton(false);
      setUploadAccessError(error.response.data.message);
    }
  };

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.title, defaultValue.description, defaultValue.dueDate]);

  const handleClose = () => {
    setSelectedFile(null);
    setKey(null);
    setFileUploadUrl(null);
    setIsUploadAccess(false);
    setUploadAccessError(null);
    reset(defaultValue);
    setOpenAssignmentDialog(false);
  };

  const changeHandler = (event) => {
    const selected = event.target.files[0];
    if (!selected) {
      return;
    }
    setSelectedFile(selected);
    setIsFilePicked(true);
    setKey(event.target.files[0]?.name);
  };

  const onSubmit = async (data) => {
    console.log('data', refinePayload(data));
  };

  return (
    <>
      <Dialog open={openAssignmentDialog} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Assignment', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name="title"
              type="text"
              control={control}
              label="Title"
              helperText={errors?.title?.message}
              errors={!!errors.title}
            />
            <FormInputText
              name="description"
              type="text"
              control={control}
              label="Description"
              helperText={errors?.description?.message}
              errors={!!errors.description}
            />
            <FormInputDate
              name="dueDate"
              control={control}
              label="Due Date"
              helperText={errors?.dueDate?.message}
              errors={!!errors.dueDate}
            />
          </Grid>
        </GridForm>
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <UploadBox>
              <SmallIconButton>
                <label
                  htmlFor="contained-button-file"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <UploadText variant="h6">Attach Document</UploadText>
                  <RoundedCloudUpload fontSize="large" />
                  <Input
                    //accept='image/*'
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={changeHandler}
                  />
                </label>
              </SmallIconButton>
              {loadingButton && <ButtonSpinnerProgress size={24} />}
            </UploadBox>
          </Grid>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            isLoading
          )}
        </GridForm>
      </Dialog>
    </>
  );
};

export default AssignmentDialog;
