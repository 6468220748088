import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { reportOptions, intialPageSize } from 'constants';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import {
  getAvailableReports,
  getReportExecutions,
  getOutputReportExecution,
  getReportExecution,
  deleteReportExecution,
} from '../services/reportToolApi';
import { getDataGridHeading, useNotification } from 'utils/commonUtils';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { AttendanceFields } from 'layouts/applications/attendance/Attendance.styled';
import {
  reportExecutionColumns,
  getReportExecutionListData,
} from './ReportToolDataGrid';
import {
  setDataGridCounts,
  resetDataGridCounts,
} from 'utils/commonSlice/dataGridCounts/dataGridCountsSlice';
import {
  setReportToolExecutionPagination,
  setReportToolPaginationPreviousKey,
  resetReportToolExecutionPagination,
} from '../store/reportToolExecutionSlice/reportToolExecutionSlice';
import ReportExecutionDialog from './ReportExecutionDialog/ReportExecutionDialog';
import { downloadReportExecutionFile } from './utils/reportToolComponentUtils';
import ViewReportExecutionDialog from './ViewReportExecutionDialog/ViewReportExecutionDialog';
import { setReportExecution } from 'layouts/reportTool/store/reportExecutionSlice/reportExecutionSlice';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';

const ReportToolComponent = () => {
  const [reportExecutionDialog, setReportExecutionDialog] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [viewDialogLoading, setViewDialogLoading] = useState(false);
  const [availableReportList, setAvailableReportList] = useState(null);
  const [reportExecutionId, setReportExecutionId] = useState('');
  const [viewReportExecutionDialog, setViewReportExecutionDialog] =
    useState(false);

  const dispatch = useDispatch();

  const {
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  } = useNotification();

  const dataGridCounts = useSelector(
    (state) => state?.totalDataGridCounts?.dataGridCounts
  );
  const totalNumber = dataGridCounts?.reduce((acc, curr) => acc + curr, 0);

  const [reportValue, setReportValue] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reportExecutionPageKey, setReportExecutionPageKey] = useState('');
  const [reportExecutionList, setreportExecutionList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
    total: 3000,
    currentPaginationKey: '',
    nextPaginationKey: '',
    count: '',
  });

  const { pageSize, page, nextPaginationKey, count } = reportExecutionList;

  const reportToolExecutionPagination = useSelector(
    (state) =>
      state?.reportToolExecutionPaginationKeyList?.reportToolExecutionPagination
  );

  const defaultValue = {
    report: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.report]);

  useEffect(() => {
    if (page === 1) {
      dispatch(resetReportToolExecutionPagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
    }
  }, []);

  useEffect(() => {
    if (reportValue === 'none') {
      setreportExecutionList((prev) => ({
        ...prev,
        rows: [],
      }));
      return;
    }
    fetchReportExecutions();
  }, [pageSize, page, reportValue]);

  const handleNewReportExecution = async () => {
    try {
      setIsFormLoading(true);
      const availableReports = await getAvailableReports();
      setAvailableReportList(availableReports?.reports);
      setIsFormLoading(false);
      setReportExecutionDialog(true);
    } catch (error) {
      setIsFormLoading(false);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const fetchReportExecutions = async () => {
    try {
      setreportExecutionList((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const reports = await getReportExecutions(
        pageSize,
        reportExecutionPageKey,
        reportValue
      );

      setreportExecutionList((prev) => ({
        ...prev,
        isLoading: false,
        currentPaginationKey: reports?.currentPaginationKey,
        nextPaginationKey: reports?.nextPaginationKey,
        count: reports?.count,
        rows: getReportExecutionListData(reports),
      }));
    } catch (error) {
      const message = error?.response?.data?.message;
      let errorMessage;
      try {
        errorMessage = JSON.parse(message);
      } catch (err) {
        errorMessage = message;
      }
      setOpenNotification(true);
      setNotificationMessage(errorMessage?.[0]?.msg || errorMessage);
      setreportExecutionList((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (!reportExecutionList?.isLoading) {
      if (count < pageSize) {
        setreportExecutionList((prveState) => ({
          ...prveState,
          total: totalNumber,
        }));
      }
    }
  }, [count, pageSize]);

  const handelPageChange = (page) => {
    setreportExecutionList((prveState) => ({
      ...prveState,
      page: page + 1,
    }));
    const paginationIdValue = {
      id: page,
      value: nextPaginationKey,
    };
    if (reportToolExecutionPagination?.find((item) => item.id === page)) {
      const getPreviousPaginationKey = () => {
        const getPaginationKey = reportToolExecutionPagination?.find(
          (item) => item.id === page
        );
        return getPaginationKey?.value;
      };
      const previousPaginationKey = getPreviousPaginationKey();
      dispatch(setReportToolPaginationPreviousKey(previousPaginationKey));
      setReportExecutionPageKey(previousPaginationKey);
    } else {
      dispatch(setDataGridCounts(count));
      dispatch(setReportToolExecutionPagination(paginationIdValue));
      setReportExecutionPageKey(nextPaginationKey);
    }
    if (page < 1) {
      setreportExecutionList((prveState) => ({
        ...prveState,
        total: 3000,
      }));
      dispatch(resetReportToolExecutionPagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
      setReportExecutionPageKey('');
    }
  };

  const handleReport = (e) => {
    setReportValue(e.target.value);
  };

  const handleOnPageSizeChange = (newPageSize) => {
    const isCountLessThanPageSize = count < pageSize;
    const totalValue = isCountLessThanPageSize ? totalNumber : 3000;

    setreportExecutionList((prevState) => ({
      ...prevState,
      pageSize: newPageSize,
      page: 1,
      total: totalValue,
    }));

    if (!isCountLessThanPageSize) {
      dispatch(resetReportToolExecutionPagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(newPageSize));
    }
  };

  const handleDownloadReportExecution = (params) => {
    downloadReportExecutionFile(
      params,
      getOutputReportExecution,
      handleErrorResponse,
      setIsFormLoading
    );
  };

  const handleViewReportExecution = async (params) => {
    setViewDialogLoading(true);
    const executionID = params?.row?.id;
    try {
      const reportExecution = await getReportExecution(executionID);
      setViewReportExecutionDialog(true);
      dispatch(setReportExecution(reportExecution));
      setViewDialogLoading(false);
    } catch (error) {
      setViewDialogLoading(false);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const handleDeleteReportExecution = (params) => {
    setOpenDeleteDialog(true);
    setReportExecutionId(params?.row?.id);
  };

  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading(
            'Report Tool',
            'New Report Execution',
            handleNewReportExecution
          )}
          <AttendanceFields container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormInputDropdown
                name="report"
                type="text"
                control={control}
                options={reportOptions}
                defaultValue={defaultValue?.report}
                label="Report"
                register={register('report', {
                  onChange: (e) => {
                    handleReport(e);
                  },
                })}
              />
            </Grid>
          </AttendanceFields>
          <DeltaDataGrid
            listData={reportExecutionList}
            columns={reportExecutionColumns(
              handleDownloadReportExecution,
              handleViewReportExecution,
              handleDeleteReportExecution,
              reportExecutionList
            )}
            handelPageChange={handelPageChange}
            setListData={handleOnPageSizeChange}
          />
        </Card>
      </MDBox>
      <ReportExecutionDialog
        open={reportExecutionDialog}
        setOpen={setReportExecutionDialog}
        isFormLoading={isFormLoading}
        availableReportList={availableReportList}
        fetchReportExecutions={fetchReportExecutions}
      />
      <ViewReportExecutionDialog
        open={viewReportExecutionDialog}
        setOpen={setViewReportExecutionDialog}
        viewDialogLoading={viewDialogLoading}
      />
      <NotificationPopup />
      <CommonDeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        contentText="Are you sure you want to delete this report ?"
        fetchData={fetchReportExecutions}
        snakBarName="report"
        commonDeleteApi={deleteReportExecution}
        commonDeleteId={reportExecutionId}
      />
    </>
  );
};

export default ReportToolComponent;
