import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm } from 'components/Ui/styled';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  taskSuccess,
  PaperProps,
  dateFormat,
  booleanOptions,
  characterLimit,
} from 'constants';
import {
  showBackdropLoader,
  getDialogHeading,
  renderSaveCancelButtons,
  fieldValidation,
  useNotification,
  onSave,
  currentDate,
  minDateValidation,
  refinePayload,
} from 'utils/commonUtils';
import {
  setTitle,
  setDescription,
  setDueOn,
  setAssignToSupervisor,
  setUser,
} from 'layouts/taskManagement/store/taskSlice/taskSlice';
import {
  postTask,
  putTask,
} from 'layouts/taskManagement/services/taskManagementApi';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';
import { setTaskNote } from 'layouts/taskManagement/store/taskSlice/taskSlice';

const taskSchema = yup.object().shape({
  title: fieldValidation(characterLimit),
  description: fieldValidation(4000),
  note: fieldValidation(4000, false),
  dueOn: minDateValidation(),
});

const TaskDialog = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, fetchTasks, isLoading, supervisedReadWrite } = props;
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [loadingButton, setLoadingButton] = useState(false);

  const canSuperviseTasks = usePermissionCustomHook('CAN_SUPERVISE_TASKS');

  const taskForm = useSelector((state) => state?.taskForm?.item);
  const {
    isNewtask,
    id,
    title,
    description,
    dueOn,
    assignToSupervisor,
    user,
    note,
  } = taskForm || {};

  const defaultValue = {
    id: id,
    title: title,
    description: description,
    dueOn: dueOn,
    assignToSupervisor: assignToSupervisor,
    user: user ? user : '',
    note: note,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(taskSchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.id,
    defaultValue.title,
    defaultValue.description,
    defaultValue.dueOn,
    defaultValue.assignToSupervisor,
    defaultValue.user,
    defaultValue.note,
  ]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValue);
  };
  const onSubmit = () => {
    const rawPayload = {
      title,
      description,
      note,
      dueOn: dueOn ? moment(dueOn).format(dateFormat) : null,
      ...(assignToSupervisor && { user }),
    };

    const payload = {
      data: refinePayload(rawPayload),
    };

    onSave(
      taskSuccess,
      isNewtask,
      postTask,
      putTask,
      payload,
      id,
      fetchTasks,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {showBackdropLoader(isLoading)}
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Task', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}></Grid>
          <FormInputText
            name="title"
            type="text"
            control={control}
            label="Title"
            helperText={errors?.title?.message}
            errors={!!errors.title}
            register={register('title', {
              onChange: (e) => dispatch(setTitle(e.target.value)),
            })}
          />
          <FormInputText
            name="description"
            type="text"
            control={control}
            label="Description"
            helperText={errors?.description?.message}
            errors={!!errors.description}
            register={register('description', {
              onChange: (e) => dispatch(setDescription(e.target.value)),
            })}
          />
          <FormInputDate
            name="dueOn"
            control={control}
            label="Due On"
            minDate={currentDate}
            value={dueOn}
            helperText={errors?.dueOn?.message}
            errors={!!errors.dueOn}
            register={register('dueOn', {
              onChange: (e) => dispatch(setDueOn(e.target.value)),
            })}
          />
          {isNewtask && canSuperviseTasks && (
            <FormInputDropdown
              name="assignToSupervisor"
              type="text"
              control={control}
              options={booleanOptions}
              label="Do you want to assign this task to any another user ?"
              register={register('assignToSupervisor', {
                onChange: (e) =>
                  dispatch(setAssignToSupervisor(e.target.value)),
              })}
            />
          )}
          {assignToSupervisor && (
            <FormInputDropdown
              name="user"
              type="text"
              control={control}
              options={supervisedReadWrite}
              label="Supervisor"
              register={register('user', {
                onChange: (e) => dispatch(setUser(e.target.value)),
              })}
            />
          )}
          <FormInputText
            name="note"
            type="text"
            control={control}
            label="Note"
            multiline={true}
            rows={2}
            helperText={errors?.note?.message}
            errors={!!errors.note}
            register={register('note', {
              onChange: (e) => dispatch(setTaskNote(e.target.value)),
            })}
          />
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default TaskDialog;
