const initialState = {
  newAssetFlag: false,
  newNotesFlag: false,
  id: '',
  assetTag: null,
  model: null,
  manu: null,
  serial: null,
  acquired: '',
  verified: '',
  notes: [],
  typeId: '',
  locationId: '',
  buildingId: '',
  fundingId: '',
  selectedNotesId: '',
  macAddress: null,
  ipAddress: null,
  retired: '',
};

export default initialState;
