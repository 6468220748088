import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import {
  PaperPropsCheck,
  onlineAttendanceSuccess,
  canWriteStudentTrackerDataPermissionMessage,
} from 'constants';
import { GridForm } from 'components/Ui/styled';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { yupResolver } from '@hookform/resolvers/yup';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import * as yup from 'yup';
import {
  getDialogHeading,
  renderSaveCancelButtons,
  showBackdropLoader,
  useNotification,
  getSnackBar,
  dropDownValidation,
  fieldValidation,
  refinePayload,
  permissionCheck,
} from 'utils/commonUtils';
import {
  setStudentTrackerCellData,
  setStudentTrackerColumnName,
} from 'layouts/studentTracking/store/studentTrackerCellSlice/studentTrackerCellSlice';
import { putStudentTrackerCell } from 'layouts/studentTracking/services/studentTrackerApi';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';

const validationSchema = yup.object().shape({
  status: dropDownValidation,
  note: fieldValidation(4000, false),
});

const StudentTrackerUpdateDialog = (props) => {
  const { id, field, row, api, getTableData } = props;

  const dispatch = useDispatch();

  const {
    setOpenNotification,
    setNotificationMessage,
    handleErrorResponse,
    NotificationPopup,
  } = useNotification();

  const canWriteStudentTrackerData = usePermissionCustomHook(
    'CAN_WRITE_STUDENT_TRACKER_DATA'
  );

  const [isStudentTrackerUpdateDialog, setIsStudentTrackerUpdateDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const studentTrackerCell = useSelector((state) => state.studentTrackerCell);
  const { studentId, studentTrackerColumnName } = studentTrackerCell || {};

  const studentTrackerFilters = useSelector(
    (state) => state.studentTrackerFilters
  );

  const { trackerId } = studentTrackerFilters || {};

  const studentTrackerStatusInfo = row?.data?.[field];

  const { checked, note, status, version } = studentTrackerStatusInfo || {};

  const trackerStatus = useSelector((state) => state.trackerStatus);

  const statusDropDownOptions = trackerStatus?.map((item) => ({
    id: item,
    value: item,
  }));

  const defaultValue = {
    checked: checked ? checked : false,
    note: note || '',
    status: status,
    version: version,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset, control, formState, watch, setValue } =
    useFunction;
  const { errors } = formState;

  const statusDropdownValue = watch('status');

  useEffect(() => {
    setValue('checked', statusDropdownValue === 'Completed');
  }, [statusDropdownValue, setValue]);

  useEffect(() => {
    checkPermission();
  }, []);

  const checkPermission = () => {
    permissionCheck(
      canWriteStudentTrackerData,
      () => {
        setIsStudentTrackerUpdateDialog(true);
        setIsLoading(true);
      },
      handleErrorResponse,
      canWriteStudentTrackerDataPermissionMessage
    );
  };

  useEffect(() => {
    dispatch(setStudentTrackerCellData(row));
    dispatch(setStudentTrackerColumnName(field));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.status,
    defaultValue.note,
    defaultValue.checked,
    defaultValue.version,
  ]);

  const handleClose = () => {
    api.setCellMode(id, field, 'view');
    setIsStudentTrackerUpdateDialog(false);
  };

  const onSubmit = async (data) => {
    const { status, note, checked } = data;
    setLoadingButton(true);
    const snackBar = getSnackBar(onlineAttendanceSuccess);
    const payload = {
      checked: checked,
      column: studentTrackerColumnName,
      note: note,
      previousVersion: version ? version : null,
      status: status,
      studentId: studentId,
    };
    await putStudentTrackerCell(refinePayload(payload), trackerId)
      .then((response) => {
        dispatch(displaySnackbar(snackBar));
        api.setCellMode(id, field, 'view');
        dispatch(displaySnackbar(snackBar));
        setIsStudentTrackerUpdateDialog(false);
        getTableData();
      })
      .catch((e) => {
        setOpenNotification(true);
        setNotificationMessage(e.response.data.message);
      });
    setLoadingButton(false);
  };

  if (isLoading) {
    return showBackdropLoader(isLoading);
  }

  return (
    <>
      <Dialog open={isStudentTrackerUpdateDialog} PaperProps={PaperPropsCheck}>
        <>
          {getDialogHeading('Set Student Tracker', handleClose)}
          <GridForm container spacing={2}>
            <Grid item xs={12}>
              <FormInputDropdown
                name="status"
                type="text"
                control={control}
                options={statusDropDownOptions}
                label="Status"
                errors={!!errors.status}
                helperText={errors?.status?.message}
              />
              <FormInputText
                name="note"
                type="text"
                control={control}
                multiline={true}
                rows={3}
                label="Note"
                errors={!!errors.note}
                helperText={errors?.note?.message}
              />
              <>
                <MDBox pt={4}></MDBox>
                <FormInputSwitch
                  control={control}
                  name="checked"
                  label="Completed"
                  checked={defaultValue.checked}
                />
              </>
            </Grid>
          </GridForm>
          <MDBox pt={2}></MDBox>
          <GridForm container spacing={2}>
            <Grid item xs={12}>
              {renderSaveCancelButtons(
                handleClose,
                handleSubmit(onSubmit),
                loadingButton
              )}
            </Grid>
          </GridForm>
        </>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default StudentTrackerUpdateDialog;

export const renderStudentTrackerUpdateDialog = (params, getTableData) => {
  return <StudentTrackerUpdateDialog {...params} getTableData={getTableData} />;
};
