import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { USER_BASE_URL_APPEND } from 'constants';

export const getUsersList = async () => {
  const userList = await API.get('delta-api', 'user-mgmt/users', {});
  const { users } = userList;
  return users || [];
};

export const getUserGroups = createAsyncThunk(
  'delta/getUserGroups',
  async () => {
    const data = await API.get('delta-api', 'user-mgmt/groups', {});
    return data === undefined ? null : data;
  }
);

export const putUser = async (payload, username) => {
  const myInit = {
    body: payload,
  };
  await API.put('delta-api', `user-mgmt/users/${username}`, myInit);
};

export const postUser = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `user-mgmt/users`, myInit);
};

export const deleteUser = async (username) => {
  const encodedUsername = encodeURIComponent(username);
  await API.del('delta-api', `user-mgmt/users/${encodedUsername}`, {});
};

export const resendInvitation = async (userName) => {
  await API.post(
    'delta-api',
    `user-mgmt/users/${userName}/resendInvitation`,
    {}
  );
};

export const postUserLocation = async (locationId, username) => {
  await API.post(
    'delta-api',
    `asset-mgmt/locations/${locationId}/users/${username}`,
    {}
  );
};

export const deleteUserLocation = async (locationId, username) => {
  await API.del(
    'delta-api',
    `asset-mgmt/locations/${locationId}/users/${username}`,
    {}
  );
};

export const getUser = async (username) => {
  const user = await API.get('delta-api', `${USER_BASE_URL_APPEND}${username}`);
  return user;
};
