import React from 'react';
import {
  ControlForm,
  HelperTextForm,
  DropdownLabelText,
} from 'components/Ui/styled';
import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { noneOption } from 'constants';

const FormInputDropdown = (props) => {
  const {
    name,
    control,
    label,
    options = [],
    multiple,
    checked,
    renderValue,
    defaultValue,
    errors,
    helperText,
    disabled,
    isNameHasValue,
    nameProperKey,
    isPropName,
    propName,
    readOnly,
    filter,
    hideCheckbox
  } = props || {};

  const dropdownOptions = filter ? [noneOption, ...options] : options;

  const generateSingleOptions = () => {
    return dropdownOptions?.map((item = [], index) => {
      return (
        <MenuItem
          key={index}
          value={isNameHasValue ? item[nameProperKey] : item.id}
        >
          {multiple && !hideCheckbox && <Checkbox checked={checked?.indexOf(item.value) > -1} />}
          {!isPropName && isNameHasValue ? item[nameProperKey] : item.value}
          {isPropName && item[propName]}
        </MenuItem>
      );
    });
  };

  return (
    <ControlForm variant="standard" data-testid={label}>
      <DropdownLabelText primary={errors ? 'true' : undefined}>
        {label}
      </DropdownLabelText>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue={defaultValue}
            onChange={onChange}
            value={value == null ? (multiple ? [] : '') : value}
            multiple={multiple}
            renderValue={renderValue}
            error={errors}
            disabled={disabled}
            inputProps={{ readOnly: readOnly }}
            data-testid={name}
          >
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
      <HelperTextForm>{helperText}</HelperTextForm>
    </ControlForm>
  );
};

export default FormInputDropdown;
