import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, RoundedIconCloudDownload, DeleteActionIcon } from 'components/Ui/styled';
import { fileTypes } from './reportToolComponentConstants';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

export const downloadReportExecutionFile = async (
  params,
  getOutputReportExecution,
  handleErrorResponse,
  setIsFormLoading
) => {
  const { row } = params;

  try {
    setIsFormLoading(true);
    const scores = await getOutputReportExecution(row.id);
    const downloadFileType = scores.type;

    const extension = fileTypes[downloadFileType] || 'bin';

    const blob = new Blob([scores], { type: downloadFileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `report_scores.${extension}`);
    document.body.appendChild(link);
    link.click();
    setIsFormLoading(false);
  } catch (error) {
    const blob = error?.response?.data;
    const errorMessage = await blob?.text();
    const errorJson = JSON.parse(errorMessage);
    handleErrorResponse(errorJson.message);
    setIsFormLoading(false);
  }
};

export const renderReportToolExecutionAction = (
  params,
  handleDownloadReportExecution,
  handleViewReportExecution,
  handleDeleteReportExecution
) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon
        onClick={() => handleViewReportExecution(params)}
        data-testid="viewButtonIcon"
      >
        <VisibilityRoundedIcon />
      </ButtonIcon>
      <ButtonIcon
        onClick={() => handleDownloadReportExecution(params)}
        data-testid="buttonIcon"
      >
          <RoundedIconCloudDownload />
      </ButtonIcon>
      <ButtonIcon
        onClick={() => handleDeleteReportExecution(params)}
        data-testid="deleteButtonIcon"
      >
        <DeleteActionIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};

 export const generateReportExecutionDefaultValues = (params) => {
    const defaults = {};
    if (params) {
      Object.entries(params).forEach(([key, field]) => {
        switch (field.fieldType) {
          case 'multiselect':
            defaults[key] = [];
            break;
          case 'select':
            defaults[key] = '';
            break;
          case 'bool':
            defaults[key] = false;
            break;
          case 'text':
          default:
            defaults[key] = '';
            break;
        }
      });
    }
    return defaults;
  };
