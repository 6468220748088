import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { ChipGrid } from 'components/Ui/styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import { dropDownSameOptions, camelCaseToTitleCase } from 'utils/commonUtils';

const ReportExecutionDynamicForm = (props) => {
  const { control, params, errors } = props;

  return (
    <>
      {Object.entries(params).map(([key, field]) => {
        const { name, fieldType, allowedValues } = field;
        if (fieldType === 'multiselect') {
          return (
            <Grid item xs={12} key={key}>
              <FormInputDropdown
                name={name}
                control={control}
                multiple={true}
                hideCheckbox={true}
                renderValue={(selected) => (
                  <ChipGrid>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </ChipGrid>
                )}
                options={dropDownSameOptions(allowedValues)}
                errors={!!errors[name]}
                helperText={errors?.[name]?.message}
                label={camelCaseToTitleCase(name)}
              />
            </Grid>
          );
        }
        if (fieldType === 'select') {
          return (
            <Grid item xs={12} key={key}>
              <FormInputDropdown
                name={name}
                control={control}
                options={dropDownSameOptions(allowedValues)}
                errors={!!errors[name]}
                helperText={errors?.[name]?.message}
                label={camelCaseToTitleCase(name)}
              />
            </Grid>
          );
        }
        if (fieldType === 'bool') {
          return (
            <Grid item xs={12} sm={6} key={key}>
              <FormInputSwitch
                name={name}
                control={control}
                label={camelCaseToTitleCase(name)}
                errors={!!errors[name]}
                helperText={errors?.[name]?.message}
              />
            </Grid>
          );
        }
        if (fieldType === 'text') {
          return (
            <Grid item xs={12} key={key}>
              <FormInputText
                name={name}
                control={control}
                label={camelCaseToTitleCase(name)}
                errors={!!errors[name]}
                helperText={errors?.[name]?.message}
              />
            </Grid>
          );
        }
        return null;
      })}
    </>
  );
};

export default ReportExecutionDynamicForm;
