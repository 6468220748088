import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import {
  HeadingMuiTypography,
  GridWrapper,
  AccordianContainer,
} from 'components/Ui/styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import MDTypography from 'components/MDTypography';
import { useSelector, useDispatch } from 'react-redux';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItems from 'components/Common/ListItems';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StudentProfileNote from './StudentProfileNote';
import { useNotification } from 'utils/commonUtils';
import { getSearchStudentDetails } from 'layouts/students/service/studentsApi';
import { getStudentData } from 'layouts/students/store/studentDetailsSlice';
import { setScoresOptions } from 'layouts/studentManagement/store/scores/scoresOptionsSlice/scoresOptionsSlice';
import { getScoresOptions } from 'layouts/studentManagement/services/studentProfileApi';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import AssignProgramAccordian from './AssignProgramAccordian';

const StudentProfileTab = () => {
  const dispatch = useDispatch();
  const studentDetails = useSelector((state) => state.studentDetails);

  const {
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  } = useNotification();

  const [isExpanded, setIsExpanded] = useState(true);
  const [isParentNoteExpanded, setIsParentNoteExpanded] = useState(true);
  const [program, setProgram] = useState([]);
  const [isAssignExpanded, setAssignExpanded] = useState(true);
  const [isAssignProgramExpanded, setIsAssignProgramExpanded] = useState(true);

  const { profile, guardians = {} } = studentDetails || {};

  const {
    firstName,
    lastName,
    raceEthnicity,
    gender,
    homePrimaryLanguage,
    assignedAssets,
    note,
    resourceId,
    gradeLevel,
    studentType,
  } = profile || {};

  const defaultValue = {
    studentFirstName: firstName,
    studentMiddleName: '',
    studentLastName: lastName,
    studentLocalID: resourceId,
    ethnicity: raceEthnicity,
    gender: gender,
    primaryLanguages: homePrimaryLanguage,
    assignedAssets: assignedAssets,
    generalNote: '',
    guardian1FirstName: guardians[0]?.firstName,
    guardian1LastName: guardians[0]?.lastName,
    guardian1Email: guardians[0]?.emailAddress,
    guardian1HomePhone: guardians[0]?.homePhone,
    guardian1WorkPhone: guardians[0]?.workPhone,
    guardian1CellPhone: guardians[0]?.cellPhone,
    guardian1MailingAddress: guardians[0]?.mailingAddress,
    guardian2FirstName: '',
    guardian2LastName: '',
    guardian2Email: '',
    guardian2HomePhone: '',
    guardian2WorkPhone: '',
    guardian2CellPhone: '',
    guardian2MailingAddress: '',
    program: program,
    gradeLevel: gradeLevel,
    studentType: studentType,
  };

  useEffect(() => {
    fetchScoresOptions();
  }, []);

  const fetchScoresOptions = async () => {
    try {
      const scoresOptions = await getScoresOptions();
      dispatch(setScoresOptions(scoresOptions));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset, control, register, formState, setValue } =
    useFunction;
  const { errors } = formState;

  const handleProgramChange = (event) => {
    const {
      target: { value },
    } = event;
    setProgram(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.studentFirstName,
    defaultValue.studentMiddleName,
    defaultValue.studentLastName,
    defaultValue.studentLocalID,
    defaultValue.ethnicity,
    defaultValue.gender,
    defaultValue.primaryLanguages,
    defaultValue.assignedAssets,
    defaultValue.generalNote,
    defaultValue.guardian1FirstName,
    defaultValue.guardian1LastName,
    defaultValue.guardian1Email,
    defaultValue.guardian1HomePhone,
    defaultValue.guardian1WorkPhone,
    defaultValue.guardian1CellPhone,
    defaultValue.guardian1MailingAddress,
    defaultValue.guardian2FirstName,
    defaultValue.guardian2LastName,
    defaultValue.guardian2Email,
    defaultValue.guardian2HomePhone,
    defaultValue.guardian2WorkPhone,
    defaultValue.guardian2CellPhone,
    defaultValue.guardian2MailingAddress,
    defaultValue.gradeLevel,
    defaultValue.studentType,
  ]);

  // const onSubmit = (data) => {
  //   console.log('data', data);
  // };

  const renderStudentAssignedAssets = () => {
    return (
      <>
        {studentDetails?.assignedAssets?.map((item, index) => {
          const { type, assetTag } = item;
          const title = `${type.val} ( Tag: ${assetTag} )`;
          return (
            <Box key={index}>
              <ListItems title={title} />
            </Box>
          );
        })}
      </>
    );
  };

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  const handleParentNoteAccordianChange = () => {
    setIsParentNoteExpanded(!isParentNoteExpanded);
  };

  const handleClose = () => {
    setValue('program', []);
    setProgram([]);
  };

  const fetchStudentNoteInfo = async () => {
    const studentNoteInfoData = await getSearchStudentDetails(resourceId);
    dispatch(getStudentData(studentNoteInfoData));
  };

  const handleAssignAssetsAccordion = () => {
    setAssignExpanded(!isAssignExpanded);
  };

  const handleAssignProgramAccordian = () => {
    setIsAssignProgramExpanded(!isAssignProgramExpanded);
  };

  return (
    <>
      <CustomizedSnackbars />
      <HeadingMuiTypography variant="h6">
        General Information
      </HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="studentFirstName"
                type="text"
                control={control}
                label="Student First Name"
              />
            </Grid>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="studentMiddleName"
                type="text"
                control={control}
                label="Student Middle Name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="studentLastName"
                type="text"
                control={control}
                label="Student Last Name"
              />
            </Grid>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="studentType"
                type="text"
                control={control}
                label="Student Type"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="studentLocalID"
                type="text"
                control={control}
                label="Student Local ID"
              />
            </Grid>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="gradeLevel"
                type="text"
                control={control}
                label="Grade"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="ethnicity"
                type="text"
                control={control}
                label="Ethnicity"
              />
            </Grid>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="gender"
                type="text"
                control={control}
                label="Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.7}>
              <FormInputText
                name="primaryLanguages"
                type="text"
                control={control}
                label="Primary Languages"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={9.5}>
              <AccordianContainer
                defaultExpanded={false}
                expanded={isAssignExpanded}
                onChange={handleAssignAssetsAccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="d7">Assigned Assets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {renderStudentAssignedAssets()}
                </AccordionDetails>
              </AccordianContainer>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={9.5}>
              <AccordianContainer
                defaultExpanded={false}
                expanded={isExpanded}
                onChange={handleAccordionChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="d7" data-testid="note_accordion">
                    Note
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <StudentProfileNote
                    note={note}
                    resourceId={resourceId}
                    fetchStudentNote={fetchStudentNoteInfo}
                  />
                </AccordionDetails>
              </AccordianContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <MDTypography variant="body2">Image</MDTypography>
        </Grid>
      </GridWrapper>
      <HeadingMuiTypography variant="h6">
        Parent Contact Information
      </HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian1FirstName"
            type="text"
            control={control}
            label="Guardian 1 First Name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian1LastName"
            type="text"
            control={control}
            label="Guardian 1 Last Name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian1Email"
            type="text"
            control={control}
            label="Guardian 1 Email Address"
          />
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian1HomePhone"
            type="text"
            control={control}
            label="Guardian 1 Home Phone"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian1WorkPhone"
            type="text"
            control={control}
            label="Guardian 1 Work Phone"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian1CellPhone"
            type="text"
            control={control}
            label="Guardian 1 Cell Phone"
          />
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={8}>
          <FormInputText
            name="guardian1MailingAddress"
            type="text"
            control={control}
            label="Guardian 1 Mailing Address"
          />
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian2FirstName"
            type="text"
            control={control}
            label="Guardian 2 First Name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian2LastName"
            type="text"
            control={control}
            label="Guardian 2 Last Name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian2Email"
            type="text"
            control={control}
            label="Guardian 2 Email Address"
          />
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian2HomePhone"
            type="text"
            control={control}
            label="Guardian 2 Home Phone"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian2WorkPhone"
            type="text"
            control={control}
            label="Guardian 2 Work Phone"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputText
            name="guardian2CellPhone"
            type="text"
            control={control}
            label="Guardian 2 Cell Phone"
          />
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={8}>
          <FormInputText
            name="guardian2MailingAddress"
            type="text"
            control={control}
            label="Guardian 2 Mailing Address"
          />
        </Grid>
      </GridWrapper>
      {/* <GridWrapper>
        <Grid container>
          <Grid item xs={12} md={8}>
            <AccordianContainer
              defaultExpanded={false}
              expanded={isParentNoteExpanded}
              onChange={handleParentNoteAccordianChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="d7">Note</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ParentContactNote />
              </AccordionDetails>
            </AccordianContainer>
          </Grid>
        </Grid>
      </GridWrapper> */}
      <AssignProgramAccordian
        tags={program}
        profile={profile}
        resourceId={resourceId}
        handleClose={handleClose}
        handleTags={handleProgramChange}
        handleErrorResponse={handleErrorResponse}
        isAssignProgramExpanded={isAssignProgramExpanded}
        handleAssignProgramAccordian={handleAssignProgramAccordian}
      />
      <NotificationPopup />
    </>
  );
};

export default StudentProfileTab;
