import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm, StudentProfileNoteGrid } from 'components/Ui/styled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import {
  renderSaveCancelButtons,
  getSnackBar,
  useNotification,
  refinePayload,
} from 'utils/commonUtils';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';
import { requiredTextLimit } from 'constants';
import { putStudentUpdateNote } from 'layouts/students/service/studentsApi';
import MDBox from 'components/MDBox';

const input_schema = yup.object().shape({
  note: yup.string().max(4000, requiredTextLimit).nullable(),
});

const StudentProfileNote = (props) => {
  const { note, resourceId, fetchStudentNote } = props;
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  let canUpdateAdditionalInfo = usePermissionCustomHook(
    'CAN_UPDATE_ADDITIONAL_INFO'
  );

  const defaultValue = {
    note: note,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(input_schema),
  });

  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.note]);

  const handleClose = () => {
    reset(defaultValue);
  };

  const onSubmit = async (data) => {
    setLoadingButton(true);
    const { note } = data;
    const message = 'Successfully the note is saved';
    const snackBar = getSnackBar(message);
    const payload = {
      note,
    };
    try {
      await putStudentUpdateNote(refinePayload(payload), resourceId);
      fetchStudentNote();
      setLoadingButton(false);
      dispatch(displaySnackbar(snackBar));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
      setLoadingButton(false);
    }
    setLoadingButton(false);
  };

  return (
    <>
      <CustomizedSnackbars />
      <FormInputText
        name="note"
        type="text"
        label="Note"
        control={control}
        multiline={true}
        rows={2}
        readOnly={!canUpdateAdditionalInfo}
        helperText={errors?.note?.message}
        errors={!!errors.note}
      />
      <MDBox pt={2}></MDBox>
      {canUpdateAdditionalInfo &&
        renderSaveCancelButtons(
          handleClose,
          handleSubmit(onSubmit),
          loadingButton
        )}
      <NotificationPopup />
    </>
  );
};

export default StudentProfileNote;
