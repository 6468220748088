import { createSlice } from '@reduxjs/toolkit';
import initialState from './assetInitialState';
import {
  _setNewAssetFlag,
  _setNewNotesFlag,
  _setAssets,
  _setModel,
  _setLocationId,
  _setAssetTag,
  _setTypeId,
  _setSerial,
  _setBuildingId,
  _setAcquired,
  _setVerified,
  _setFundingId,
  _setNotes,
  _setNotesDate,
  _setManu,
  _setNewNotes,
  _setSelectedNotesId,
  _setMacAddress,
  _setIpAddress,
  _setRetired
} from './assetAction';

export const assetSlice = createSlice({
  name: 'Asset',
  initialState,
  reducers: {
    setNewAssetFlag: _setNewAssetFlag,
    setNewNotesFlag: _setNewNotesFlag,
    setAssets: _setAssets,
    setAssetTag: _setAssetTag,
    setTypeId: _setTypeId,
    setModel: _setModel,
    setLocationId: _setLocationId,
    setSerial: _setSerial,
    setBuildingId: _setBuildingId,
    setAcquired: _setAcquired,
    setVerified: _setVerified,
    setFundingId: _setFundingId,
    setNotes: _setNotes,
    setNotesDate: _setNotesDate,
    setManu: _setManu,
    setNewNotes: _setNewNotes,
    setSelectedNotesId: _setSelectedNotesId,
    setMacAddress: _setMacAddress,
    setIpAddress: _setIpAddress,
    setRetired: _setRetired,
    resetAssets: () => initialState,
  },
});

export const {
  setNewAssetFlag,
  setNewNotesFlag,
  setAssets,
  setAssetTag,
  setTypeId,
  setModel,
  setLocationId,
  setSerial,
  setBuildingId,
  setAcquired,
  setVerified,
  setFundingId,
  setNotes,
  setNotesDate,
  setManu,
  setNewNotes,
  setSelectedNotesId,
  resetAssets,
  setMacAddress,
  setIpAddress,
  setRetired
} = assetSlice.actions;

export default assetSlice.reducer;
