import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import {
  getDataGridHeading,
  showBackdropLoader,
  useNotification,
  dropDownSameOptions,
  getButtonSpinner,
  truncateTooltipText,
  clearSpace,
} from 'utils/commonUtils';
import { TabWrapperBox } from 'layouts/applications/attendance/Attendance.styled';
import {
  generateAttendanceTabs,
  getGradeValue,
} from 'layouts/applications/attendance/utils/attendanceUtils';
import {
  getTrackerColumns,
  getTrackerGradeLevel,
  getTrackers,
} from 'layouts/studentTracking/services/studentTrackerApi';
import { useForm } from 'react-hook-form';
import { renderStudentTrackerUpdateDialog } from './StudentTrackerUpdateDialog';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import Grid from '@mui/material/Grid';
import { useDebouncedCallback } from 'use-debounce';
import {
  generateTrackerTabPanel,
  renderStudentTrackerCell,
  getStudentTrackerCellClass,
  getHideUnHideColumns,
} from 'layouts/studentTracking/utils/studentTrackerUtils';
import {
  setTrackerId,
  setStudentType,
  setInstructor,
  setCourse,
  setTabValue,
  resetStudentTrackerFilter,
} from 'layouts/studentTracking/store/studentTrackerFilterSlice/studentTrackerFilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentProfileFilterOptions } from 'layouts/studentManagement/services/studentProfileApi';
import { setStudentProfileFilterOptions } from 'layouts/studentManagement/store/studentProfileFilterOptionsSlice/studentProfileFilterOptionsSlice';
import Typography from '@mui/material/Typography';
import { HeadingFormGrid } from 'components/Ui/styled';
import HideColumnDialog from './HideColumnDialog';
import { getTracker } from 'layouts/studentTracking/services/studentTrackerApi';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { setTrackerStatus } from 'layouts/studentTracking/store/trackerStatusSlice/trackerStatusSlice';
import { canManageStudentTrackersPermissionMessage } from 'constants';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';

const StudentTrackerComponent = () => {
  const dispatch = useDispatch();
  const {
    setOpenNotification,
    setNotificationMessage,
    handleErrorResponse,
    NotificationPopup,
  } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [hideColumnDialog, setHideColumnDialog] = useState(false);
  const [hideColumns, setHideColumns] = useState([]);
  const [isHideColumnDialogLoading, setIsHideColumnDialogLoading] =
    useState(false);
  const [trackerDropDownList, setTrackerDropDownList] = useState([]);
  const [searchStudent, setSearchStudent] = useState(null);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [clearFilterLoading, setClearFilterLoading] = useState(false);

  const canManageStudentTrackers = usePermissionCustomHook(
    'CAN_MANAGE_STUDENT_TRACKERS'
  );

  const studentTrackerFilters = useSelector(
    (state) => state.studentTrackerFilters
  );

  const { trackerId, studentType, instructor, course, tabValue } =
    studentTrackerFilters || {};

  const studentProfileFilterOptions = useSelector(
    (state) => state.studentProfileFilterOptions
  );

  const { allStudentTypes, allInstructors, allCourseNames } =
    studentProfileFilterOptions ?? [];

  const defaultValue = {
    searchStudent: searchStudent,
    trackerId: trackerId,
    tabValue: tabValue,
    studentType: studentType,
    instructor: instructor,
    course: course,
  };

  const disableHideUnhideButton = tableData.columns.length < 1 || !trackerId;

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { control, reset, register } = useFunction;

  useEffect(() => {
    fetchTrackerOptions();
    fetchStudentProfileFilterOptionsData();
  }, []);

  const fetchTrackerOptions = async () => {
    try {
      const trackerOptions = await getTrackers();
      setTrackerDropDownList(trackerOptions?.trackers);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const fetchStudentProfileFilterOptionsData = async () => {
    try {
      const studentProfileFilterOptionsData =
        await getStudentProfileFilterOptions();
      dispatch(setStudentProfileFilterOptions(studentProfileFilterOptionsData));
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const trackerDropDownOptions = trackerDropDownList?.map((item) => ({
    id: item.trackerId,
    value: truncateTooltipText(item.name),
  }));

  const handleHideColumns = async () => {
    if (canManageStudentTrackers) {
      try {
        setIsHideColumnDialogLoading(true);
        const tracker = await getTracker(trackerId);
        const gradeValue = getGradeValue(tabValue);
        const hiddenColumnsForGrade =
          tracker?.hiddenColumnsByGrade[gradeValue] || [];
        const hideUnHideColumns = getHideUnHideColumns(
          tracker?.columns,
          hiddenColumnsForGrade
        );
        setHideColumns(hideUnHideColumns);
        setIsHideColumnDialogLoading(false);
        setHideColumnDialog(true);
      } catch (error) {
        setIsHideColumnDialogLoading(false);
        handleErrorResponse(error?.response?.data?.message);
      }
      return;
    }
    handleErrorResponse(canManageStudentTrackersPermissionMessage);
  };

  const getTableData = async () => {
    setClearFilterLoading(true);
    const gradeValue = getGradeValue(tabValue);
    const trackerColumns = await getTrackerColumns(trackerId, gradeValue);
    var columns = [
      {
        headerName: 'Student ID',
        field: 'studentId',
        width: 140,
        renderCell: (params) => {
          const studentId = params.row.studentId;
          return <Typography variant="h6">{studentId}</Typography>;
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        width: 225,
        valueGetter: (params) => {
          const firstName = params.row.studentData.firstName;
          const lastName = params.row.studentData.lastName;
          return `${firstName} ${lastName}`;
        },
        renderCell: (params) => {
          const fullName = params.value;
          return <Typography variant="h6">{fullName}</Typography>;
        },
      },
    ];
    trackerColumns?.columns?.map((item) => {
      return columns.push({
        headerName: item,
        field: item,
        width: 200,
        align: 'center',
        cellClassName: (params) => getStudentTrackerCellClass(params, item),
        renderEditCell: (params) =>
          renderStudentTrackerUpdateDialog(params, getTableData),
        renderCell: (params) => renderStudentTrackerCell(params, item),
        editable: true,
      });
    });
    try {
      let students;
      setIsLoading(true);
      students = await getTrackerGradeLevel(
        trackerId,
        gradeValue,
        studentType,
        instructor,
        course,
        clearSpace(searchStudent)
      );
      setIsLoading(false);
      const rows = Object.entries(students.data).map(([key, item]) => {
        return {
          ...item,
          id: key,
        };
      });
      setTableData({ columns: columns, rows: rows });
      setClearFilterLoading(false);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
      setIsLoading(false);
      setClearFilterLoading(false);
    }
  };

  useEffect(() => {
    if (trackerId) {
      getTableData();
    } else {
      setTableData({ columns: [], rows: [] });
    }
  }, [trackerId, tabValue, studentType, instructor, course, searchStudent]);

  const handleTabChange = (event, newTabValue) => {
    dispatch(setTabValue(newTabValue));
  };

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.trackerId,
    defaultValue.tabValue,
    defaultValue.studentType,
    defaultValue.instructor,
    defaultValue.course,
    defaultValue.searchStudent,
  ]);

  useEffect(() => {
    if (trackerId && trackerDropDownList) {
      const statusOptions = trackerDropDownList?.find(
        (item) => trackerId === item.trackerId
      )?.statusOptions;
      dispatch(setTrackerStatus(statusOptions));
    }
  }, [trackerId, trackerDropDownList]);

  const handleStudentSearch = useDebouncedCallback((e) => {
    setSearchStudent(e.target.value);
  }, 500);

  const handleClearFilters = () => {
    setSearchStudent(null);
    dispatch(resetStudentTrackerFilter());
  };

  return (
    <>
      <Card>
        {getDataGridHeading('Student Tracker')}
        <HeadingFormGrid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormInputDropdown
              name="trackerId"
              control={control}
              options={trackerDropDownOptions}
              defaultValue={defaultValue?.trackerId}
              label="Trackers"
              register={register('trackerId', {
                onChange: (e) => dispatch(setTrackerId(e.target.value)),
              })}
              filter
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormInputDropdown
              name="studentType"
              control={control}
              options={dropDownSameOptions(allStudentTypes)}
              defaultValue={defaultValue?.studentType}
              label="Student Type"
              register={register('studentType', {
                onChange: (e) => dispatch(setStudentType(e.target.value)),
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormInputDropdown
              name="instructor"
              control={control}
              options={dropDownSameOptions(allInstructors)}
              defaultValue={defaultValue?.instructor}
              label="Instructor"
              register={register('instructor', {
                onChange: (e) => dispatch(setInstructor(e.target.value)),
              })}
              filter
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormInputDropdown
              name="course"
              control={control}
              options={dropDownSameOptions(allCourseNames)}
              defaultValue={defaultValue?.course}
              label="Course"
              register={register('course', {
                onChange: (e) => dispatch(setCourse(e.target.value)),
              })}
              filter
            />
          </Grid>
        </HeadingFormGrid>
        {trackerId && (
          <HeadingFormGrid container spacing={2}>
            <Grid item xs={12}>
              <FormInputText
                name="searchStudent"
                type="text"
                control={control}
                label="Search"
                placeholder="Search students"
                register={register('searchStudent', {
                  onChange: (e) => {
                    handleStudentSearch(e);
                  },
                })}
              />
            </Grid>
          </HeadingFormGrid>
        )}
        <HeadingFormGrid container spacing={2}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6} md={3}>
            {getButtonSpinner(
              false,
              handleClearFilters,
              'Clear Filters',
              clearFilterLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {getButtonSpinner(
              false,
              handleHideColumns,
              'Hide/Un-hide Columns',
              disableHideUnhideButton
            )}
          </Grid>
        </HeadingFormGrid>
        <TabWrapperBox>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Student Tracker"
          >
            {generateAttendanceTabs()}
          </Tabs>
          {isLoading && showBackdropLoader(isLoading)}
          {generateTrackerTabPanel(tabValue, tableData, [], false)}
        </TabWrapperBox>
      </Card>
      <HideColumnDialog
        trackerId={trackerId}
        hideColumnDialog={hideColumnDialog}
        setHideColumnDialog={setHideColumnDialog}
        hideColumns={hideColumns}
        getTableData={getTableData}
        tabValue={tabValue}
        isHideColumnDialogLoading={isHideColumnDialogLoading}
      />
      <NotificationPopup />
    </>
  );
};

export default StudentTrackerComponent;
