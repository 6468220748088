import { rowsPerPage } from 'constants';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getRowClassName, getRowHeight, formatDate } from 'utils/commonUtils';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { renderSharedFolderAction } from './SharedFolderAction';
import { TypographyLink } from 'components/Ui/styled';

export const getSharedFolderDataGrid = (
  sharedFolders,
  columns,
  setSharedFolders
) => {
  const { rows, pageSize, isLoading } = sharedFolders;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setSharedFolders((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};

export const sharedFoldersColumns = (
  hideAction,
  typographyLink,
  handleUpdateSharedFolder,
  handleSharedFolderAccess,
  handleArchiveSharedFolders,
  handleUnarchiveSharedFolders,
  handleReadUser,
  handleWriteUser,
  handleReadGroup,
  handleWriteGroup,
  handleSharedFolderVersions
) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    hide: hideAction,
    width: 120,
    renderCell: (params) =>
      renderSharedFolderAction(
        params,
        handleUpdateSharedFolder,
        handleSharedFolderAccess,
        handleArchiveSharedFolders,
        handleUnarchiveSharedFolders
      ),
  },
  {
    headerName: 'Name',
    field: 'name',
    renderCell: renderCellExpand,
    width: 200,
  },
  {
    headerName: 'Description',
    field: 'description',
    renderCell: renderCellExpand,
    width: 300,
  },
  {
    headerName: 'Read Users',
    field: 'readUser',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink variant='h6' onClick={() => handleReadUser(params)}>
            {params.row.readUser}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.readUser}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Write Users',
    field: 'writeUser',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink variant='h6' onClick={() => handleWriteUser(params)}>
            {params.row.writeUser}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.writeUser}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Read Groups',
    field: 'readGroup',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink variant='h6' onClick={() => handleReadGroup(params)}>
            {params.row.readGroup}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.readGroup}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Write Groups',
    field: 'writeGroup',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink variant='h6' onClick={() => handleWriteGroup(params)}>
            {params.row.writeGroup}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.writeGroup}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Versions',
    field: 'version',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink
            variant='h6'
            onClick={() => handleSharedFolderVersions(params)}
          >
            {params.row.version}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.version}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Created By',
    field: 'createdBy',
    renderCell: renderCellExpand,
    width: 180,
  },
  { headerName: 'Created At', field: 'createdAt', width: 150 },
  {
    headerName: 'Modified By',
    field: 'modifiedBy',
    renderCell: renderCellExpand,
    width: 180,
  },
  { headerName: 'Modified At', field: 'modifiedAt', width: 150 },
  { headerName: 'Archived', field: 'archived', type: 'boolean', width: 150 },
];

export const getSharedFoldersListData = (sharedFoldersData) => {
  const items = sharedFoldersData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item) => ({
    ...item,
    id: item.id,
    name: item.name,
    description: item.description,
    readUser: item.readUsers?.length,
    writeUser: item.writeUsers?.length,
    readGroup: item.readGroups?.length,
    writeGroup: item.writeGroups?.length,
    readUserList: item.readUsers,
    writeUserList: item.writeUsers,
    readGroupList: item.readGroups,
    writeGroupList: item.writeGroups,
    version: item.version,
    createdBy: item.createdBy,
    createdAt: formatDate(item.createdAt),
    modifiedBy: item.modifiedBy,
    modifiedAt: formatDate(item.modifiedAt),
    archived: item.archived,
  }));
};
