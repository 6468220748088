import { renderPublicProfileAction } from './PublicProfileAction';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';

export const publicProfileColumns = (handleViewPublicProfile) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: (params) =>
      renderPublicProfileAction(params, handleViewPublicProfile),
  },
  { headerName: 'User Name', field: 'username', width: 250 },
  {
    headerName: 'User Id',
    field: 'userId',
    flex: 1,
    renderCell: renderCellExpand,
    hide: true,
  },
  {
    headerName: 'Email',
    field: 'email',
    renderCell: renderCellExpand,
    width: 250,
  },
  {
    headerName: 'First Name',
    field: 'givenName',
    renderCell: renderCellExpand,
    width: 150,
  },
  {
    headerName: 'Last Name',
    field: 'familyName',
    renderCell: renderCellExpand,
    width: 150,
  },
  {
    headerName: 'Job Title',
    field: 'jobTitle',
    renderCell: renderCellExpand,
    width: 150,
  },
  {
    headerName: 'Vonage Extension',
    field: 'vonageExtension',
    renderCell: renderCellExpand,
    width: 150,
  },
];

export const getPublicProfileListData = (publicProfileListData) => {
  const items = publicProfileListData?.profiles;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      username,
      userId,
      email,
      givenName,
      familyName,
      jobTitle,
      vonageExtension,
    }) => ({
      id: userId,
      username,
      email,
      givenName,
      familyName,
      jobTitle,
      vonageExtension,
    })
  );
};
