import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm, useFieldArray } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import { useSelector, useDispatch } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  showBackdropLoader,
  onSave,
  emptyFunction,
  hasDuplicates,
  fieldValidation,
  clearSpace,
} from 'utils/commonUtils';
import {
  PaperProps,
  trackerSuccess,
  requiredLimit,
  columnNameError,
  characterLimit,
} from 'constants';
import { postTracker } from 'layouts/studentTracking/services/studentTrackerApi';
import MultiFormTextField from 'components/Common/Forms/MultiFormTextField';
import { resetTrackingForm } from 'layouts/studentTracking/store/trackingSlice/trackingSlice';

const schema = yup.object().shape({
  name: fieldValidation(characterLimit),
  fields: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .trim()
        .max(100, requiredLimit)
        .required('This field is required'),
    })
  ),
});

const TrackingDialog = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, isFormLoading, fetchTrackers } = props;
  const {
    setOpenNotification,
    setNotificationMessage,
    handleErrorResponse,
    NotificationPopup,
  } = useNotification();

  const [loadingButton, setLoadingButton] = useState(false);

  const trackingForm = useSelector((state) => state?.trackingForm);

  const { name, arrayOfColumns, isNewTracking } = trackingForm || {};

  const editColumns = arrayOfColumns?.map((col) => ({ value: col }));

  const defaultValue = {
    name: name,
    fields: isNewTracking ? [{ value: '' }] : editColumns,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
  });

  useEffect(() => {
    reset({
      ...defaultValue,
      fields: defaultValue.fields?.length
        ? defaultValue.fields
        : [{ value: '' }],
    });
  }, [name, JSON.stringify(defaultValue.fields)]);

  const handleClose = () => {
    reset(defaultValue);
    dispatch(resetTrackingForm());
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const refinePayload = (payload) => {
      return Object.fromEntries(
        Object.entries(payload).map(([key, value]) => {
          if (typeof value === 'string') {
            return [key, clearSpace(value)];
          }
          if (Array.isArray(value)) {
            return [
              key,
              value.map((item) =>
                typeof item === 'string' ? clearSpace(item) : item
              ),
            ];
          }
          return [key, value];
        })
      );
    };

    const { name, fields } = data;

    const columns = fields?.map((item) => item.value.trim());
    const isDuplicates = hasDuplicates(columns);

    const payload = {
      name,
      columns,
    };

    if (isDuplicates) {
      handleErrorResponse(columnNameError);
      return;
    }
    onSave(
      trackerSuccess,
      isNewTracking,
      postTracker,
      emptyFunction,
      refinePayload(payload),
      '',
      fetchTrackers,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {showBackdropLoader(isFormLoading)}
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Tracker', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name="name"
              type="text"
              control={control}
              label="Name"
              helperText={errors?.name?.message}
              errors={!!errors.name}
            />
            {fields.map((field, index) => (
              <Grid item key={index}>
                <MultiFormTextField
                  label="Column"
                  fields={fields}
                  field={field}
                  index={index}
                  append={append}
                  remove={remove}
                  control={control}
                  errors={errors}
                  maxColumns={10}
                />
              </Grid>
            ))}
          </Grid>
        </GridForm>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton,
            !isNewTracking
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default TrackingDialog;
