import { DataGridPro } from '@mui/x-data-grid-pro';
import { rowsPerPage } from 'constants';
import { GridWrapper } from 'layouts/inventory/Inventory.styled';
import {
  handleBooleanProp,
  getRowClassName,
  getRowHeight,
  useSortModel,
} from 'utils/commonUtils';

const DeltaDataGrid = (props) => {
  const {
    listData,
    columns,
    setListData,
    initialState,
    handelPageChange,
    getDetailPanelContent,
    getDetailPanelHeight,
    rowsPerPage2,
    defaultSortField = 'name',
  } = props || {};

  const { rows = [], pageSize, isLoading, total, page } = listData || {};
  const [sortModel, handleSortModelChange] = useSortModel(defaultSortField);

  const paginationProps = {};
  const isLessThanTenRows = rows?.length < 10;

  if (handelPageChange) {
    paginationProps.paginationMode = 'server';
  }

  const autoHeightProps = isLessThanTenRows ? { autoHeight: true } : {};

  const rowsPerPageOptions = rowsPerPage2 ? rowsPerPage2 : rowsPerPage;

  return (
    <GridWrapper container primary={handleBooleanProp(isLessThanTenRows)}>
      <DataGridPro
        {...autoHeightProps}
        getRowHeight={getRowHeight}
        rows={rows}
        columns={columns}
        page={page && page - 1}
        rowCount={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
        pageSize={pageSize}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        getRowClassName={(params) => getRowClassName(params)}
        onPageChange={(newPage) =>
          handelPageChange && handelPageChange(newPage)
        }
        loading={isLoading}
        hideFooterPagination={isLoading}
        onPageSizeChange={(newPageSize) => setListData(newPageSize)}
        initialState={initialState}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        {...paginationProps}
      />
    </GridWrapper>
  );
};

export default DeltaDataGrid;
